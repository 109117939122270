import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CheckboxGroupContext from './context'
import { useConfig } from '../ConfigProvider'

const Checkbox = React.forwardRef((props, ref) => {

	const {
		name: nameContext,
		//value: groupValue,
		//onChange: onGroupChange,
		color: colorContext
	} = useContext(CheckboxGroupContext)

	const { 
		color, 
		className, 
		onChange, 
		children, 
		disabled,
		readOnly,
		name = nameContext,
		defaultChecked,
		value,
		checked,
		labelRef,
		field,
		...rest
	} = props

	const { themeColor, primaryColorLevel } = useConfig()

	const checkboxColor = color || colorContext || `${themeColor}-${primaryColorLevel}`

	const checkboxDefaultClass = `checkbox text-${checkboxColor}`
	const checkboxColorClass = disabled && 'disabled'
	const labelDefaultClass = `checkbox-label`
	const labelDisabledClass = disabled && 'disabled'

	const checkBoxClass = classNames(
		checkboxDefaultClass,
		checkboxColorClass,
	)

	const labelClass = classNames (
		labelDefaultClass,
		labelDisabledClass,
		className
	)

	return (
		<label ref={labelRef} className={labelClass}>
			<input
				ref={ref}
				className={checkBoxClass}
				type="checkbox"
				disabled={disabled}
				readOnly={readOnly}
				onChange={onChange}
				name={name}
				checked={checked}

				{...field}
				{...rest}
			/>
			{children ? <span className={classNames('ltr:ml-2 rtl:mr-2', disabled ? 'opacity-50' : '')}>{children}</span> : null}
    	</label>
	)
})

Checkbox.propTypes = {
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	defaultChecked: PropTypes.bool,
	color: PropTypes.string,
	onChange: PropTypes.func,
	labelRef: PropTypes.string,
	value: PropTypes.any,
}

export default Checkbox
