import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  uuid: "",
  name: "",
  in_valid_name: null,
  price_list: [
    {
      volume: "",
      in_valid_volume: null,
      price: null,
      in_valid_price: null,
      image: "",
      in_valid_image: null,
    },
  ],
}
const product = createSlice({
  name: 'product',
  initialState: initialState,
  reducers: {
    /** Сброс state */
    reset_product(state) {
      Object.assign(state, initialState);
    },
    set_product(state, action) {
      state = action.payload
      return state
    },
    set_name(state, action) {
      state.name = action.payload
    },
    set_in_valid_name(state, action) {
      state.in_valid_name = action.payload
    },
    set_image(state, action) {
      if (state.price_list[action.payload.index].image !== undefined) {
        state.price_list[action.payload.index].image = action.payload.value
      }
    },
    set_in_valid_image(state, action) {
      if (state.price_list[action.payload.index].in_valid_image !== undefined) {
        state.price_list[action.payload.index].in_valid_image = action.payload.value
      }
    },
    set_volume(state, action) {
      if (state.price_list[action.payload.index].volume !== undefined) {
        state.price_list[action.payload.index].volume = action.payload.value
      }
    },
    set_in_valid_volume(state, action) {
      if (state.price_list[action.payload.index].in_valid_volume !== undefined) {
        state.price_list[action.payload.index].in_valid_volume = action.payload.value
      }
    },
    set_price(state, action) {
      if (state.price_list[action.payload.index].price !== undefined) {
        state.price_list[action.payload.index].price = action.payload.value
      }
    },
    set_in_valid_price(state, action) {
      if (state.price_list[action.payload.index].in_valid_price !== undefined) {
        state.price_list[action.payload.index].in_valid_price = action.payload.value
      }
    },
    add_container(state) {
      state.price_list.push(
        {
          volume: "",
          in_valid_volume: null,
          price: null,
          in_valid_price: null,
          image: "",
          in_valid_image: null,
        }
      )
    },
    delete_container(state, action) {
      state.price_list.splice(action.payload, 1)
    },
  },
});

export const {
  reset_product,
  set_product,
  set_name,
  set_in_valid_name,
  set_image,
  set_in_valid_image,
  set_volume,
  set_in_valid_volume,
  set_price,
  set_in_valid_price,
  add_container,
  delete_container,
} = product.actions;

export default product.reducer;
