import {createClient} from "@supabase/supabase-js";

export const getSupabase = (access_token) => {
  return createClient(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_SUPABASE_KEY,
    {
      global: {headers: {Authorization: `Bearer ${access_token}`}},
    }
  );
};