import {createSlice} from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
  name: 'auth/session',
  initialState: {
    access: "",
    access_supabase: "",
    is_session: null,
    signedIn: false,
  },
  reducers: {
    onSignInSuccess: (state, action) => {
      state.signedIn = true
      //state.is_session = true
      state.access = action.payload.access
      state.access_supabase = action.payload.access_supabase
    },
    onSignOutSuccess: (state) => {
      state.signedIn = false
      state.token = ''
    },
    set_is_session(state, action) {
      state.is_session = action.payload;
    },
  },
})

export const {onSignInSuccess, onSignOutSuccess, set_is_session} = sessionSlice.actions

export default sessionSlice.reducer