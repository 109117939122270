import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  message: "",
  selected_emails: []
}
const mailing = createSlice({
  name: 'mailing',
  initialState: initialState,
  reducers: {
    set_message(state, action) {
      state.message = action.payload
    },
    set_selected_emails(state, action) {
      state.selected_emails = action.payload
    },
    add_selected_emails(state, action) {
      state.selected_emails.push(action.payload);
    },
    del_selected_emails(state, action) {
      state.selected_emails = state.selected_emails.filter((email) => email !== action.payload);
      return state;
    },
  },
});

export const {
  set_selected_emails,
  set_message,
  del_selected_emails,
  add_selected_emails
} = mailing.actions;

export default mailing.reducer;
