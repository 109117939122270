import {createSlice} from "@reduxjs/toolkit";



const initialState = {

  /**
   * Объект уведомления. State оставим пустым, чтобы при авторизации единожды запросить список уведомлений,
   * а повторно запрашивать при изменении в таблице supabase
   *     {
   *       id: null,
   *       user_id: null,
   *       message: "",
   *       event: {
   *         type: "", // create или update
   *         object: {
   *           id: null,
   *           name: "", // наименование измененного объекта (счет - bill, заказ - order, и др.)
   *         }
   *       }
   *     }
   * */

  notifications: null,
  selected_notifications: [],

  /** Последнее сообщение */
  event: null,
  open_modal_delete: false,
  loading: false,
};

/** Быстрое оформление заказа на главной странице */

const notifications_slice = createSlice({
  name: "notifications",
  initialState: initialState,

  reducers: {
    /** Сброс state */
    reset_notifications(state) {
      Object.assign(state, initialState);
    },
    /** Выбор категории по тегу */
    set_notifications(state, action) {
      state.notifications = action.payload;
    },

    /** Добавление/удаление выбранных уведомлений */
    set_selected_notifications(state, action) {
      state.selected_notifications = action.payload;
    },
    /** Добавление выбранного уведомления в state выбранных уведомлений */
    add_selected_notifications(state, action) {
      state.selected_notifications.push(action.payload);
    },
    /** Удаление выбранного уведомления из state выбранных уведомлений */
    del_selected_notifications(state, action) {
      state.selected_notifications = state.selected_notifications.filter((notifications) => notifications.uuid !== action.payload);
      return state;
    },

    set_event(state, action) {
      state.event = action.payload;
    },
    set_open_modal_delete(state, action) {
      state.open_modal_delete = action.payload;
    },
    set_loading(state, action) {
      state.loading = action.payload;
    },
  },

});

export const {
  reset_notifications,
  set_notifications,
  set_selected_notifications,
  add_selected_notifications,
  del_selected_notifications,
  set_event,
  set_open_modal_delete,
  set_loading
} = notifications_slice.actions;

export const notifications = notifications_slice.reducer;
