import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
  user_id: null,
  email: "",
  first_name: null,
  last_name: null,
  patronymic: null,
  phone: null,
  roles: [],
};

export const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state = {...state, ...action.payload};
      if (action.payload?.roles?.length === 0) {
        state = {...state, ...state.roles.push("user")};
      }
      return state;
    },
    userLoggedOut: () => initialState,
  },
});

export const {setUser} = userSlice.actions;

export default userSlice.reducer;