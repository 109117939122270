import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect} from "react";
import axios from "axios";
import {set_loading} from "../store/errors-server";
import {onSignInSuccess} from "../store/auth/sessionSlice";


export const useTokenUpdate = () => {
  const dispatch = useDispatch();
  const is_session = useSelector((state) => state.auth.session.is_session);

  /** Токены */
  const access_supabase = useSelector((state) => state.auth.session.access_supabase);

  const get_jwt = useCallback(() => {
    dispatch(set_loading(true))

    //return jwt
    return axios
      .get(`${process.env.REACT_APP_WEBSITE_AUTH_API}/users/login/`)
      .then((res) => {
        dispatch(set_loading(false));
        //dispatch(set_access_supabase(res.data.access_supabase));
        //dispatch(set_access(res.data.access));

        dispatch(onSignInSuccess({ access: res.data.access,  access_supabase: res.data.access_supabase }))
      })
      .catch(() => {
        dispatch(set_loading(false));
        //dispatch(set_access_supabase(""));
        //dispatch(set_access(""));
        dispatch(onSignInSuccess({ access: "",  access_supabase: "" }))
      });
  }, [dispatch]);


  useEffect(() => {
    is_session && get_jwt();
  }, [is_session, get_jwt]);
  /** Обновление токенов */
  useEffect(() => {
    if (access_supabase) {
      const access = JSON?.parse(atob(access_supabase.split(".")[1]));
      const expires = new Date(access.exp * 1000).getTime() - Date.now() - 2 * 60 * 1000;
      setTimeout(() => {
        get_jwt();
      }, expires);
    }
  }, [access_supabase, get_jwt]);
};
